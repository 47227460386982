package features.wordsProgressReportsModal

import Modal
import antd.Button
import app.useAppDispatch
import csstype.Display
import csstype.FlexDirection
import csstype.px
import emotion.react.css
import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import online.interactiver.common.enums.ELanguage
import pages.duoSettings.weeklyReport.SetWeeklyReport
import pages.duoSettings.weeklyReport.getWeeklyReportRequest
import pages.duoSettings.weeklyReport.selectWeeklyReport
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import react.FC
import react.Props
import react.redux.useSelector
import react.router.useNavigate
import react.useEffectOnce
import react.useState
import shared.components.description.Description
import widgets.LanguageAutoContent.ui.generateButton
import kotlin.js.Date

internal const val localReportsStorageKey = "words-progress-reports-date"

val WordsProgressReportsModal = FC<Props> {
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val weeklyReport = useSelector(selectWeeklyReport)
    val navigate = useNavigate()
    val dispatch = useAppDispatch()

    val (isOpen, setIsOpen) = useState(false)

    useEffectOnce {
        if (weeklyReport != null) {
            return@useEffectOnce
        }

        GlobalScope.launch {
            val response = getWeeklyReportRequest() ?: return@launch

            dispatch(SetWeeklyReport(response))
            // turned reports off until most of people have not enough progress
//            val startOfTheWeek = getStartOfTheWeekMillisFromEpoch()
//            val lsDate = localStorage.getItem(localReportsStorageKey)?.toDoubleOrNull()
//            if (lsDate == null || startOfTheWeek > lsDate) {
//                localStorage.setItem(localReportsStorageKey, startOfTheWeek.toString())
//                setIsOpen(true)
//                return@launch
//            }

            setIsOpen(false)
        }
    }

    Modal {
        open = isOpen
        onCancel = { setIsOpen(false) }
        title = getWeeklyReportTitle(nativeLanguage)
        footer = null
        bodyStyle = jso {
            display = Display.flex
            flexDirection = FlexDirection.column
            gap = 15.px
        }

        Description {
            +getDescription(nativeLanguage)
        }

        Button {
            css(generateButton)
            onClick = {
                setIsOpen(false)
                navigate("/duo/weekly-report")
            }
            +getWatchButtonText(nativeLanguage)
        }
    }
}

fun getWeeklyReportTitle(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Недельный отчёт"
} else {
    "Weekly report"
}

fun getDescription(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Ваш недельный отчёт готов!"
} else {
    "Your weekly report is ready!"
}

fun getWatchButtonText(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Смотреть"
} else {
    "Watch"
}

fun getStartOfTheWeekMillisFromEpoch(): Double {
    val currentDate = Date()
    val daysToSubtract = if (currentDate.getUTCDay() == 0) 6 else currentDate.getUTCDay() - 1
    return Date.UTC(
        currentDate.getUTCFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate() - daysToSubtract
    )
}