package online.interactiver.common.sliderErrorReports

import kotlinx.serialization.Serializable

@Serializable
data class SliderErrorReportRequest(
    val sliderUuid: String,
    val numberOfExercise: Int,
    val exerciseContent: String,
    val mistakeDetails: String
)
