package entities.solvedTasks.ui

import antd.Badge
import antd.Button
import antd.Popover
import app.useAppDispatch
import csstype.Background
import csstype.px
import emotion.react.css
import entities.solvedTasks.getDaysWithTasksRequest
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import pages.constructor.ui.components.elements.blueFilter
import pages.constructor.ui.components.elements.whiteFilter
import pages.duoSettings.weeklyReport.selectWeeklyReport
import pages.studentDuo.SetStudentDuoDaysWithTasks
import pages.studentDuo.selectStudentDuoDaysWithTasks
import pages.studentDuo.selectStudentDuoUserProfile
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.redux.useSelector
import react.router.useNavigate
import shared.components.Icon
import utils.types.extend
import utils.types.impl
import widgets.AdminPanel.ui.iconButton
import widgets.DuoCourses.ui.DuoCourses.DuoCourses

@Serializable
data class DaysWithTasks(val days: Int, val tasks: Int)

val SolvedTasks = FC<Props> {
    val daysWithTasks = useSelector(selectStudentDuoDaysWithTasks)
    val userProfile = useSelector(selectStudentDuoUserProfile)
    val weeklyReport = useSelector(selectWeeklyReport)
    val dispatch = useAppDispatch()
    val navigate = useNavigate()
    val (isMenuOpened, setIsMenuOpened) = useState(false)

    useEffectOnce {
        GlobalScope.launch {
            val daysWithTasksResponse = getDaysWithTasksRequest()

            dispatch(SetStudentDuoDaysWithTasks(daysWithTasksResponse ?: DaysWithTasks(0, 0)))
        }
    }

    if (daysWithTasks == null || userProfile == null) {
        return@FC
    }

    div {
        css(container)
        Badge {
            count = 0//if (weeklyReport?.watched != false) 0 else 1

            div {
                onClick = {
                    navigate("/duo/settings")
                }
                if (userProfile.photoSrc == null) {
                    css(profile.extend(imageReplacement))
                    +userProfile.userName.first().uppercase()
                } else {
                    css(profile)
                    img {
                        css(image)
                        src = userProfile.photoSrc
                    }
                }
                if (userProfile.tariffPlan.uppercase() == "PRO") {
                    div {
                        css(proLabel)
                        +"PRO"
                    }
                }
                if (userProfile.discount > 0) {
                    div {
                        css(discountLabel)
                        +"${userProfile.discount}$"
                    }
                }
            }
        }
        p {
            css(text)
            +"${daysWithTasks.days} \uD83C\uDF1E\uD83C\uDF1C"
        }
        p {
            css(text)
            +"${daysWithTasks.tasks} ⭐\uFE0F\uD83D\uDCAA"
        }
        Popover {
            trigger = "click"
            align = impl {
                points = arrayOf("tr", "tl")
                offset = arrayOf(0, 72)
            }
            arrowPointAtCenter = false
            content = createElement(DuoCourses, impl { close = { setIsMenuOpened(false) } })
            open = isMenuOpened
            onOpenChange = { isOpen: Boolean ->
                setIsMenuOpened(isOpen)
            }
            Button {
                css(iconButton.extend {
                    background = "inherit".unsafeCast<Background>()
                })
                onClick = {
                    setIsMenuOpened(true)
                }
                Icon {
                    css(whiteFilter.extend {
                        width = 32.px
                        height = 32.px
                        hover(blueFilter)
                    })
                    src = "ic_menu_44x44.svg"
                }
            }
        }
    }
}