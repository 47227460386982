package pages.payment.ui.PaymentCard

import kotlin.math.floor

fun getMonthPaymentCosts(
    cost: Int,
    durationMonths: Int,
    promoCodeDiscount: Int?
): Pair<Int, Int?> {
    val factor = if (promoCodeDiscount == null) null else {
        (100 - promoCodeDiscount) / 100.0
    }
    val costWithoutDiscount = cost / durationMonths
    val costWithPromoCodeDiscount = if (factor != null) {
        floor(cost * factor).toInt() / durationMonths
    } else {
        null
    }
    return Pair(
        costWithoutDiscount,
        costWithPromoCodeDiscount
    )
}