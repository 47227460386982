package online.interactiver.common.account.orders

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class OrderRequest(
    /**
     * Name of the order or payment.
     * Example: "Standard rates, payment system: Allpay" or "Promotion from 01.01.2024, payment system: Allpay"
     *
     * required
     */
    var name: String,
    /**
     * Payment amount. Number rounded to hundredths.
     * Example: 1000.00
     *
     * required
     */
    var amount: Double,
    /**
     * Billing currency.
     *
     * optional
     */
    var currency: String,
    /**
     * Language of the payment page where the customer enters his bank card details. ENG is default.
     *
     * optional
     */
    var lang: String,
    /**
     * Customer name in any language.
     *
     * required
     */
    @SerialName("client_name")
    var clientName: String,
    /**
     * Customer e-mail.
     *
     * required
     */
    @SerialName("client_email")
    var clientEmail: String,
    /**
     * Customer phone number.
     *
     * optional
     */
    @SerialName("client_phone")
    var clientPhone: String = "",
    @SerialName("payment_account_id")
    var paymentAccountId: Int?,
    @SerialName("payment_system")
    var paymentSystem: String,
    @SerialName("tariff_plan")
    var tariffPlan: String = "",
    @SerialName("duration_months")
    var durationMonths: Long,
    @SerialName("is_success")
    val isSuccess: Boolean = false,
    @SerialName("success_url")
    var successUrl: String = "",
    @SerialName("fail_url")
    var failUrl: String = "",
    @SerialName("promo_code")
    var promoCode: String? = null,
    @SerialName("number_of_collaborators")
    var numberOfCollaborators: Int = 0,
    @SerialName("user_id")
    var userId: Int? = null,
    @SerialName("discount")
    var discount: Int = 0
) {
    constructor() : this("", 0.0, "USD", "ENG", "", "", "", null, "", "", 0L)
}
