package pages.duoSettings.weeklyReport

import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import jsonClient
import online.interactiver.common.weeklyReport.WeeklyReport
import utils.getToken

suspend fun getWeeklyReportRequest(): WeeklyReport? {
    return try {
        val response = jsonClient.get("/api/v1/weekly-report") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return null
        }

        response.body()
    } catch (e: ResponseException) {
        null
    }
}

suspend fun watchWeeklyReportRequest(id: Int): Boolean {
    return try {
        val response = jsonClient.put("/api/v1/weekly-report/$id") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}