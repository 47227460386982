package features.reportErrorModal.ui

import csstype.*
import utils.types.CssStyle

val modal: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 15.px
}

val textArea: CssStyle = {
    height = important(110.px)
    resize = important(None.none)
    fontSize = 18.px
}
