package pages.payment.ui.PaymentCard

import antd.Button
import csstype.*
import emotion.react.css
import entities.errorModal.store.ErrorModalState
import entities.errorModal.ui.ErrorModal
import entities.modalLoader.ModalLoaderState
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.user.UserProfile
import pages.constructor.ui.components.modalLoader.LanguageAutoModalLoader
import pages.payment.payRequest
import pages.payment.ui.Payment.EPaymentPerks
import pages.payment.ui.Payment.PromoCodeMeta
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.router.dom.useSearchParams
import react.useEffectOnce
import react.useState
import shared.components.description.Description
import shared.components.header.Header
import utils.types.extend
import widgets.LanguageAutoContent.ui.generateButton

external interface PaymentCardProps : Props {
    var cost: Int
    var header: String
    var description: String
    var additionalInfo: String
    var discount: Int?
    var userProfile: UserProfile
    var durationMonths: Int
    var promoCode: PromoCodeMeta?
    var nativeLanguage: String
    var numberOfCollaborators: Int?
}

val PaymentCard = FC<PaymentCardProps> { props ->
    val nativeLanguage = props.nativeLanguage
    val (errorState, setErrorState) = useState(ErrorModalState(open = false))
    val (loader, setLoader) = useState(ModalLoaderState(loading = false, text = null))
    val (searchParams, setSearchParams) = useSearchParams()

    val fromLanguageAuto = searchParams.get(EPaymentPerks.LANGUAGE_AUTO.param)?.toBooleanStrictOrNull()

    fun closeLoader() {
        setLoader(ModalLoaderState(loading = false, text = null))
    }

    useEffectOnce {
        val wasPaymentFailed = searchParams.get("failed")?.toBooleanStrictOrNull()
        if (wasPaymentFailed != true) {
            return@useEffectOnce
        }

        searchParams.delete("failed")
        setSearchParams(searchParams)
        setErrorState(
            errorState.copy(
                open = true,
                text = "Error handling payment"
            )
        )
    }

    val (
        costWithoutDiscount,
        costWithPromoCodeDiscount
    ) = getMonthPaymentCosts(
        props.cost,
        props.durationMonths,
        props.promoCode?.discount
    )
    val finalPaymentCost = costWithPromoCodeDiscount ?: costWithoutDiscount

    LanguageAutoModalLoader {
        loading = loader.loading
        text = loader.text
        showSuccessfulLoading = loader.showSuccessfulLoading
        subText = loader.subText
    }
    ErrorModal {
        isOpen = errorState.open
        title = errorState.title
        text = errorState.text
        onClose = { setErrorState(ErrorModalState(open = false)) }
    }
    div {
        css(paymentCard(props.discount != null))
        if (props.discount != null) {
            p {
                css(discount)
                +"${props.discount}% ${cheaper(nativeLanguage)}"
            }
        }
        div {
            css(header)
            Header {
                text = props.header
                fontSize = 1.25.rem
                lineHeight = 1.625.rem
            }
            Description {
                fontSize = 16.0
                lineHeight = 21.6
                +props.description
            }
        }
        val errorReportsDiscount = if (props.userProfile.discount == 0) {
            0
        } else if (props.userProfile.discount > 0.7 * finalPaymentCost * props.durationMonths) {
            (finalPaymentCost * props.durationMonths * 0.7).toInt()
        } else {
            props.userProfile.discount
        }
        Button {
            css(generateButton.extend {
                gap = 8.px
            })
            onClick = {
                setLoader(ModalLoaderState(loading = true, text = connectingToPaymentSystem(nativeLanguage)))
                GlobalScope.launch {
                    val response = payRequest(
                        finalPaymentCost * props.durationMonths - errorReportsDiscount,
                        props.userProfile.paymentAccountId,
                        props.userProfile.userName,
                        props.userProfile.email,
                        props.durationMonths,
                        props.promoCode?.promoCode,
                        successUrl = if (fromLanguageAuto == true) {
                            window.location.href.replace(window.location.pathname, "/exercises")
                        } else {
                            window.location.href.replace(
                                window.location.pathname,
                                "/duo/content?confirm_payment=${true}"
                            )
                        },
                        numberOfCollaborators = props.numberOfCollaborators ?: 0,
                        userId = props.userProfile.userId,
                        errorReportsDiscount
                    )

                    closeLoader()
                    if (response.data == null || response.data == "null") {
                        setErrorState(
                            errorState.copy(
                                open = true,
                                text = "Error handling payment"
                            )
                        )
                        return@launch
                    }

                    window.location.replace(response.data)
                }
            }
            if (costWithPromoCodeDiscount != null) {
                CrossedPayment {
                    cost = costWithoutDiscount
                }
            }

            +"$${costWithPromoCodeDiscount ?: costWithoutDiscount} / ${monthTitle(nativeLanguage)}"
        }

        Description {
            fontSize = 16.0
            lineHeight = 21.6
            if (props.userProfile.discount != 0) {
                CrossedPayment {
                    cost = finalPaymentCost * props.durationMonths
                }
            }
            +if (props.durationMonths > 1 || props.userProfile.discount != 0) {
                "$${finalPaymentCost * props.durationMonths - errorReportsDiscount} ${props.additionalInfo}"
            } else props.additionalInfo
        }
        if (props.userProfile.discount != 0) {
            div {
                +"$${errorReportsDiscount} ${getErrorReportsDiscount(nativeLanguage)}"
            }
        }
    }
}

private fun monthTitle(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "месяц" } else { "month" }
private fun cheaper(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "дешевле" } else { "cheaper" }
private fun connectingToPaymentSystem(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "Подключение к платежной системе" } else { "Connecting to the payment system..." }

private fun getErrorReportsDiscount(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "скидка за сообщения об ошибках"
} else {
    "discount for error reports"
}
