package pages.duoSettings.weeklyReport

import online.interactiver.common.weeklyReport.WeeklyReport
import pages.studentDuo.StudentDuoStoreState
import redux.RAction

open class WeeklyReportAction : RAction

data class SetWeeklyReport(val weeklyReport: WeeklyReport): WeeklyReportAction()

class WatchWeeklyReport : WeeklyReportAction()

fun weeklyReportReducer(state: WeeklyReport?, action: WeeklyReportAction): WeeklyReport? {
    return when (action) {
        is SetWeeklyReport -> action.weeklyReport
        is WatchWeeklyReport -> state?.copy(watched = true)
        else -> state
    }
}

val selectWeeklyReport = { state: StudentDuoStoreState ->
    state.weeklyReport
}