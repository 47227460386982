package online.interactiver.common.user

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UserProfile(
    @SerialName("slider_settings")
    val sliderSettings: UserSliderSettings? = null,
    @SerialName("tariff_plan")
    val tariffPlan: String = "",
    @SerialName("user_name")
    val userName: String = "",
    @SerialName("photo_src")
    val photoSrc: String? = null,
    @SerialName("about")
    val about: String,
    @SerialName("user_id")
    val userId: Int,
    @SerialName("end_payment_date")
    val endPaymentDate: Long? = null,
    @SerialName("native_language")
    val nativeLanguage: String? = null,
    @SerialName("register_date")
    val registerDate: Long? = null,
    @SerialName("language_to_learn")
    val languageToLearn: String? = null,
    @SerialName("additional_email")
    val additionalEmail: String,
    val socials: String,
    val email: String,
    @SerialName("auto_sound_exercise")
    val autoSoundExercise: Boolean,
    @SerialName("number_of_collaborators")
    val numberOfCollaborators: Int,
    @SerialName("payment_account_id")
    val paymentAccountId: Int,
    val discount: Int
)
