package pages.duoSettings.weeklyReport

import antd.Button
import app.useAppDispatch
import csstype.Auto
import csstype.Margin
import csstype.px
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELanguage
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import react.*
import react.dom.html.ReactHTML.iframe
import react.redux.useSelector
import react.router.useNavigate
import shared.components.header.Header
import utils.localization.Localization
import utils.types.extend
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoLayout.StudentDuoLayout

val WeeklyReport = VFC {
    val weeklyReport = useSelector(selectWeeklyReport)
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val dispatch = useAppDispatch()
    val navigate = useNavigate()

    useEffect(weeklyReport) {
        if (weeklyReport == null || weeklyReport.watched) return@useEffect

        GlobalScope.launch {
            watchWeeklyReportRequest(weeklyReport.id)
        }
        dispatch(WatchWeeklyReport())
    }

    StudentDuoLayout {
        buttons = FC {
            Button {
                css(newTaskButton.extend {
                    margin = Margin(0.px, Auto.auto)
                })
                onClick = {
                    navigate(-1)
                }
                +Localization.getBackButtonText(nativeLanguage)
            }
        }

        if (weeklyReport == null || weeklyReport.weeklyReport.isBlank()) {
            Header {
                text = getNoWeeklyReportTitle(nativeLanguage)
            }
            return@StudentDuoLayout
        }

        iframe {
            css(iFrame)
            srcDoc = weeklyReport.weeklyReport
        }
    }
}

fun getNoWeeklyReportTitle(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Пока что для вас нет еженедельного отчёта"
} else {
    "There is no weekly report for you yet"
}
