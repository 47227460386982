package online.interactiver.common.enums

const val defaultOpenAIModelName = "gpt-4o-mini"
const val defaultAnthropicModelName = "claude-3-haiku-20240307"

enum class EGptType(val type: String, val uiValue: String) {
    OPEN_AI("open_ai", "OpenAI"),
    ANTHROPIC("anthropic", "Anthropic"),
    DEEP_SEEK("deepseek", "DeepSeek"),
    GEMINI("gemini", "Gemini");

    companion object {
        fun fromString(value: String): EGptType? {
            return entries.find { it.type == value }
        }

        fun fromUiValue(uiValue: String): EGptType? {
            return entries.find { it.uiValue == uiValue }
        }

        fun getDefaultModelName(type: String): String {
            return when (type) {
                OPEN_AI.type -> defaultOpenAIModelName
                ANTHROPIC.type -> defaultAnthropicModelName
                else -> throw Exception("Unsupported GPT model")
            }
        }
    }
}
