package shared.hooks

import kotlinx.browser.window
import kotlinx.serialization.json.Json
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.Event
import react.useEffect

inline fun <reified T> useIframeSliderEvent(eventName: String, crossinline onEvent: (T) -> Unit) {
    useEffect {
        val handleMessage = f@{ event: Event ->
            try {
                val data: dynamic = (event as MessageEvent).data ?: return@f
                if (data.event != eventName) return@f
                onEvent(Json.decodeFromString(data.detail))
            } catch (e: Exception) {
                console.log("Error $e")
            }
        }

        window.addEventListener("message", handleMessage)
        cleanup {
            window.removeEventListener("message", handleMessage)
        }
    }
}
