package entities.solvedTasks.ui

import csstype.*
import utils.types.CssStyle
import widgets.LanguageAutoContent.ui.generateButton

val container: CssStyle = {
    width = 100.pct
    backgroundColor = Color("#597EF7")
    display = Display.flex
    alignItems = AlignItems.center
    gap = 5.px
    justifyContent = JustifyContent.spaceBetween
    padding = Padding(20.px, 13.px)
    borderTopLeftRadius = "inherit".unsafeCast<BorderTopLeftRadius>()
    borderTopRightRadius = "inherit".unsafeCast<BorderTopRightRadius>()
    boxSizing = BoxSizing.borderBox
}

val text: CssStyle = {
    margin = 0.px
    color = Color("white")
    fontWeight = integer(500)
}

val profile: CssStyle = {
    width = 44.px
    height = 44.px
    cursor = Cursor.pointer
    position = Position.relative
}

val proLabel: CssStyle = {
    background = linearGradient(Color("red"), Color("#597EF7"), Color("blue"))
    color = Color("white")
    borderRadius = 15.px
    position = Position.absolute
    top = (-10).px
    right = (-10).px
    padding = Padding(1.px, 8.px)
    fontSize = 12.px
}

val discountLabel: CssStyle = {
    background = Color("#847aff")
    color = Color("white")
    borderRadius = 15.px
    position = Position.absolute
    bottom = (-10).px
    right = (-10).px
    padding = Padding(1.px, 8.px)
    fontSize = 12.px
    minWidth = 20.px
    textAlign = TextAlign.center
}

val image: CssStyle = {
    width = 44.px
    height = 44.px
    borderRadius = 50.pct
}

val imageReplacement: CssStyle = {
    borderRadius = 50.pct
    backgroundColor = Color("white")
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    color = Color("#597EF7")
    fontWeight = FontWeight.bold
}
