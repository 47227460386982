package online.interactiver.common.weeklyReport

import kotlinx.serialization.Serializable

@Serializable
data class WeeklyReport(
    val id: Int,
    val weeklyReport: String,
    val watched: Boolean
)
