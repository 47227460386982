package features.reportErrorModal.ui

import Modal
import antd.Button
import antd.TextArea
import app.useAppDispatch
import csstype.AlignSelf
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import features.reportErrorModal.submitError
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import kotlinx.serialization.Serializable
import online.interactiver.common.autogeneration.TaskLine
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.sliderErrorReports.SliderErrorReportRequest
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.router.useParams
import react.useState
import shared.components.description.Description
import shared.components.header.Header
import shared.hooks.useIframeSliderEvent
import utils.types.extend
import widgets.LanguageAutoContent.ui.generateButton

@Serializable
data class ReportErrorFromIframeMeta(
    val numberOfExercise: Int,
    val exerciseContent: String
)

val ReportErrorModal = FC<Props> {
    val (reportErrorFromIframeMeta, setReportErrorFromIframeMeta) = useState<ReportErrorFromIframeMeta>()
    val (error, setError) = useState("")

    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val dispatch = useAppDispatch()
    val pathParams = useParams()
    val folderId = pathParams["folder_id"]?.toIntOrNull()
    val requestUuid = pathParams["request_uuid"]

    useIframeSliderEvent("onReportErrorButtonClick") { it: ReportErrorFromIframeMeta ->
        setReportErrorFromIframeMeta(it)
    }

    if (folderId == null || requestUuid == null || reportErrorFromIframeMeta == null) {
        return@FC
    }

    Modal {
        zIndex = 100
        open = reportErrorFromIframeMeta != null
        onCancel = {
            setReportErrorFromIframeMeta(null)
            setError("")
        }
        footer = null
        bodyStyle = jso(modal)

        div {
            css {
                alignSelf = AlignSelf.center
            }
            Header {
                text = getHeader(nativeLanguage)
            }
        }
        Description {
            +getDescription(nativeLanguage)
        }
        TextArea {
            css(textArea)
            value = error
            onChange = { setError(it.currentTarget.value) }
            placeholder = getPlaceholder(nativeLanguage)
        }
        Description {
            +"${getExerciseDetails(nativeLanguage)}${TaskLine(reportErrorFromIframeMeta.exerciseContent).content.toRawTaskContent()}"
        }
        Button {
            css(generateButton.extend {
                alignSelf = AlignSelf.center
            })
            onClick = f@{
                if (reportErrorFromIframeMeta == null) return@f
                GlobalScope.launch {
                    val saved = submitError(SliderErrorReportRequest(
                        sliderUuid = requestUuid,
                        numberOfExercise = reportErrorFromIframeMeta.numberOfExercise,
                        exerciseContent = reportErrorFromIframeMeta.exerciseContent,
                        mistakeDetails = error
                    ))
                    if (!saved) {
                        dispatch(OpenErrorModal("Error submitting error"))
                        return@launch
                    }

                    setReportErrorFromIframeMeta(null)
                }
            }
            +getSendButton(nativeLanguage)
        }
        Description {
            +getClarification(nativeLanguage)
        }
    }
}

fun getHeader(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Спасибо, что нашли ошибку! Напишите, пожалуйста, в чём она заключается"
} else {
    "Thank you for finding the error! Please write what it is."
}

fun getDescription(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "\uD83D\uDCCC Получите скидку \$1 на будущие покупки Inream за каждое корректное и понятное описание ошибки*"
} else {
    "\uD83D\uDCCC Get \$1 off future Inream purchases for every correct and clear description of the error*"
}

fun getPlaceholder(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Напишите про ошибку"
} else {
    "Write about the mistake"
}

fun getSendButton(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Отправить"
} else {
    "Submit"
}

fun getClarification(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "* Скидки добавляются после проверки, максимальная скидка 70%"
} else {
    "* Discounts are added after check, the max discount is 70%"
}

fun getExerciseDetails(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Материал задания: "
} else {
    "Exercise details: "
}