package pages.duoSettings.weeklyReport

import csstype.MinHeight
import csstype.None
import csstype.pct
import utils.types.CssStyle

val iFrame: CssStyle = {
    width = 100.pct
    minHeight = "inherit".unsafeCast<MinHeight>()
    border = None.none
}
