package features.reportErrorModal

import io.ktor.client.request.*
import io.ktor.http.*
import jsonClient
import online.interactiver.common.sliderErrorReports.SliderErrorReportRequest
import utils.getToken

suspend fun submitError(request: SliderErrorReportRequest): Boolean {
    return try {
        val response = jsonClient.post("/api/slider-error") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(request)
        }

        response.status.value == 200
    } catch (e: Exception) {
        return false
    }
}
