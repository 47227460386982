package pages.payment.ui.PaymentCard

import csstype.Color
import csstype.TextDecorationLine
import csstype.px
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.span

external interface CrossedPaymentProps : Props {
    var cost: Int
}

val CrossedPayment = FC<CrossedPaymentProps> { props ->
    span {
        css {
            textDecorationLine = TextDecorationLine.lineThrough
            textDecorationColor = Color("red")
            textDecorationThickness = 2.px
        }
        +"$${props.cost} "
    }
}
